export const FORM_CODE = {
	PRE_SERVICE: 'F-CS-05',
	COST_ESTIMATE: 'F-CS-06',
	PRE_DELIVERY: 'F-CS-07',
	DELIVERY: 'F-CS-08',
	PRICE_SUMMARY: 'F-CS-22'
}

export const findFormData = (formList, formCode) => {
	const formData =  formList.find((form) => form.formCode === formCode)
	return {
		...formData,
		effectiveDate: new Date(formData?.createdDatetime)?.toLocaleDateString()
	}
}
